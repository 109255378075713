import { useEffect, useState } from "react"

import IconButton from "@mui/material/IconButton"
import { styled } from "@mui/material/styles"

import { setNewsletterCookie } from "data/internal/api/newsletterCookie"
import { NEWSLETTER_TYPE } from "data/mailchimp/interfaces/INewsletter"
import { getCookie } from "lib/getCookie"

import Icon from "Components/Icon/Icon"

import Newsletter from "./Newsletter"
import { getNewsletterCookieName } from "./utils"

// we need to get a better method for determining the newsletter type
function getNewsletterType(tags: string[]) {
    if (tags.includes("Hodepine") || tags.includes("hodepine"))
        return NEWSLETTER_TYPE.NEWSLETTER_HEADACHE
    if (tags.includes("Brystkreft") || tags.includes("brystkreft"))
        return NEWSLETTER_TYPE.NEWSLETTER_BREASTCANCER
    if (tags.includes("Overgangsalder") || tags.includes("overgangsalder"))
        return NEWSLETTER_TYPE.NEWSLETTER_MENOPAUS
}

function getNewsletterBackgroundUrl(newsletter: NEWSLETTER_TYPE | undefined) {
    switch (newsletter) {
        case NEWSLETTER_TYPE.NEWSLETTER_HEADACHE: {
            return "/newsletter/Hodepine.jpg"
        }
        case NEWSLETTER_TYPE.NEWSLETTER_BREASTCANCER: {
            return "/newsletter/Brystkreft.jpg"
        }
        case NEWSLETTER_TYPE.NEWSLETTER_MENOPAUS: {
            return "/newsletter/Overgangsalder.jpg"
        }
    }
}

export default function NewsletterPopover({ tags }: { tags: string[] }) {
    const newsletter = getNewsletterType(tags)
    const backgroundUrl = getNewsletterBackgroundUrl(newsletter)

    const [open, setOpen] = useState(false)
    // show after 5 seconds
    useEffect(() => {
        setTimeout(() => {
            setOpen(true)
        }, 5000)
    }, [tags])

    const handleClose = () => {
        // set a cookie that expires in 3 hours if user closes the newsletter popover
        const cookieExpireTime = 3 * 60 * 60 * 1000

        setNewsletterCookie(newsletter!, cookieExpireTime)
        setOpen(false)
    }

    if (!open || !tags || !newsletter) return null

    const cookieName = getNewsletterCookieName(newsletter)
    if (!cookieName) return null

    const newsletterCookie = getCookie(cookieName)
    if (newsletterCookie === newsletter) return null

    return (
        <StyledContainer>
            <StyledCloseButton
                color="primary"
                aria-label="Lukk"
                onClick={handleClose}>
                <Icon name="close" />
            </StyledCloseButton>

            <Newsletter
                newsletterId={newsletter!}
                bgImage={backgroundUrl}
            />
        </StyledContainer>
    )
}

const StyledContainer = styled("div")(({ theme }) => ({
    boxShadow: theme.shadows[3],
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.newsletter.main,
    padding: 0,
    position: "sticky",
    bottom: 0,
    overflow: "hidden",
    [theme.breakpoints.up("md")]: {
        bottom: "50%",
        margin: theme.spacing(0, 4)
    }
}))

const StyledCloseButton = styled(IconButton)(({ theme }) => ({
    position: "absolute",
    alignSelf: "flex-end",
    marginRight: theme.spacing(1)
}))
