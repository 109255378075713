export default function PregnancyCardIcon() {
    return (
        <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 280 170">
            <defs>
                <style>
                    {`
          .clsPregnancy-background-1 {
            stroke: #f9cbd5;
            stroke-miterlimit: 10;
            stroke-width: 4px;
          }

          .clsPregnancy-background-1, .clsPregnancy-2 {
            fill: none;
          }

          .clsPregnancy-background-3 {
            fill: #f9cbd5;
            stroke-width: 0px;
          }

          .clsPregnancy-2 {
            stroke: #010101;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 3px;
          }

          .clsPregnancy-4 {
            opacity: .6;
          }`}
                </style>
            </defs>
            <path
                className="clsPregnancy-background-3"
                d="M184.4,141.33c14.81.16,30.88,2.74,43.42-4.91,13.85-8.44,17.97-26.63,15.72-42.36-3.4-23.74-18.93-45.55-40.61-57.02-10.98-5.81-23.22-9.02-35.47-11.47-11.36-2.28-22.99-3.95-34.53-2.87-25.32,2.39-47.22,17.59-71.28,25.6-4.53,1.51-9.24,2.81-13.14,5.5-7.71,5.32-10.75,14.96-12.31,24.01-2.73,15.9-1.99,33.52,8.04,46.37,4.5,5.76,10.56,10.16,16.68,14.26,7.27,4.86,14.9,9.48,23.42,11.71,12.17,3.18,25.05,1.24,37.49-.72,20.51-3.23,41.71-8.35,62.57-8.12"
            />
            <g className="clsPregnancy-4">
                <path
                    className="clsPregnancy-background-1"
                    d="M180.36,150.17c15.88,1.74,32.84,6.17,47.16-.64,15.81-7.53,22.25-26.46,21.58-43.45-1.01-25.64-15.26-50.5-37.25-65-11.14-7.35-23.92-12.06-36.8-15.96-11.94-3.62-24.24-6.64-36.75-6.7-27.45-.13-52.64,13.75-79.36,19.74-5.03,1.13-10.23,2.01-14.72,4.47-8.87,4.85-13.2,14.79-15.88,24.27-4.7,16.65-5.87,35.48,3.48,50.22,4.19,6.6,10.2,11.93,16.32,16.95,7.26,5.95,14.94,11.67,23.84,14.95,12.71,4.67,26.76,3.97,40.32,3.2,22.38-1.27,45.7-4.49,68.07-2.04"
                />
            </g>
            <g>
                <path
                    className="clsPregnancy-2"
                    d="M80.19,14.12c-2.99,30.08-.1,60.75,8.53,89.71.62,2.08,1.84,3.95,3.51,5.31,17.44,14.19,39.39,23.07,60.36,31.29,1.07.42,2.1.95,2.98,1.7,2.69,2.31,11.24,5.67,14.3,6.04"
                />
                <path
                    className="clsPregnancy-2"
                    d="M80.48,11.39c-.1.91-.2,1.82-.29,2.74"
                />
                <path
                    className="clsPregnancy-2"
                    d="M112.75,22.34c.02,22.18-.11,43.62-2.69,65.65,11.28,2.8,20.06,12.44,29.02,19.88,5.78,4.81,11.57,9.62,17.35,14.43,1.63-2.7,5.79-4.06,8.92-4.36,3.13-.3,6.26.4,9.31,1.14,2.01.48,4.06,1,5.83,2.06"
                />
                <line
                    className="clsPregnancy-2"
                    x1="170.52"
                    y1="141.37"
                    x2="174.64"
                    y2="143.18"
                />
                <line
                    className="clsPregnancy-2"
                    x1="174.3"
                    y1="135.19"
                    x2="180.59"
                    y2="138.36"
                />
                <line
                    className="clsPregnancy-2"
                    x1="176.36"
                    y1="128.04"
                    x2="185.49"
                    y2="132.18"
                />
                <path
                    className="clsPregnancy-2"
                    d="M136.97,11.39c1.95,1.6,3.9,3.22,5.83,4.83,5.03,4.19,10.16,8.51,13.4,14.21,4.03,7.09,4.68,16.01,1.74,23.62-2.77,7.15-11.66,11.34-19.28,11.61"
                />
                <path
                    className="clsPregnancy-2"
                    d="M158.44,54.73c15.1,4.49,27.73,16.64,32.87,31.61,3.98,11.6,3.39,24.66-1.33,35.96"
                />
                <path
                    className="clsPregnancy-2"
                    d="M92.45,122.94c-3.93,11.75-6.76,23.59-6.85,36.17"
                />
                <path
                    className="clsPregnancy-2"
                    d="M164.43,159.11c.05-3.5.63-8.78.16-12.45"
                />
            </g>
        </svg>
    )
}
