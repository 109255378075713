export default function DiseasesCardIcon() {
    return (
        <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 280 170">
            <defs>
                <style>
                    {`
          .clsDiseases-1 {
            stroke: #010101;
            stroke-width: 3px;
          }

          .clsDiseases-1, .clsDiseases-background-2 {
            fill: none;
            stroke-miterlimit: 10;
          }

          .clsDiseases-background-2 {
            stroke: #f9cbd5;
            stroke-width: 4px;
          }

          .clsDiseases-background-3 {
            fill: #f9cbd5;
            stroke-width: 0px;
          }

          .clsDiseases-4 {
            opacity: .6;
          }`}
                </style>
            </defs>
            <path
                className="clsDiseases-background-3"
                d="M185.11,138.23c14.75.17,30.75,2.81,43.24-5.04,13.8-8.66,17.9-27.32,15.66-43.46-3.38-24.36-18.85-46.73-40.44-58.5-10.94-5.96-23.13-9.25-35.33-11.77-11.31-2.33-22.9-4.06-34.39-2.94-25.22,2.45-47.02,18.05-70.99,26.27-4.51,1.55-9.2,2.88-13.09,5.64-7.68,5.46-10.71,15.35-12.26,24.64-2.72,16.32-1.99,34.39,8.01,47.57,4.48,5.91,10.51,10.42,16.61,14.63,7.24,4.99,14.84,9.73,23.33,12.02,12.12,3.26,24.95,1.27,37.34-.73,20.43-3.31,41.54-8.57,62.32-8.33"
            />
            <g className="clsDiseases-4">
                <path
                    className="clsDiseases-background-2"
                    d="M192.3,138.35c15.87-1.19,33.32.13,46.02-9.4,14.03-10.52,16.71-30.81,12.81-47.8-5.89-25.66-24.59-48.08-48.88-58.61-12.31-5.34-25.71-7.71-39.06-9.26-12.38-1.44-24.99-2.19-37.24.07-26.88,4.96-48.88,23.63-73.89,34.63-4.71,2.07-9.63,3.93-13.55,7.24-7.75,6.54-10.09,17.36-10.9,27.42-1.41,17.65,1.04,36.86,13,49.99,5.36,5.88,12.26,10.14,19.21,14.05,8.24,4.65,16.85,8.99,26.19,10.64,13.33,2.35,26.94-.97,40.07-4.26,21.65-5.44,43.86-13.01,66.22-14.69"
                />
            </g>
            <g>
                <path
                    className="clsDiseases-1"
                    d="M118.72,25.65v-8.12c0-2.99,2.42-5.41,5.41-5.41h32.47c2.99,0,5.41,2.42,5.41,5.41v8.12"
                />
                <rect
                    className="clsDiseases-1"
                    x="75.44"
                    y="28.35"
                    width="129.86"
                    height="102.81"
                    rx="10.82"
                    ry="10.82"
                />
                <circle
                    className="clsDiseases-1"
                    cx="140.37"
                    cy="82.46"
                    r="24.35"
                />
                <line
                    className="clsDiseases-1"
                    x1="78.14"
                    y1="44.58"
                    x2="202.59"
                    y2="44.58"
                />
                <line
                    className="clsDiseases-1"
                    x1="140.37"
                    y1="68.93"
                    x2="140.37"
                    y2="95.99"
                />
                <line
                    className="clsDiseases-1"
                    x1="126.84"
                    y1="82.46"
                    x2="153.9"
                    y2="82.46"
                />
            </g>
        </svg>
    )
}
