import React from "react"
import { Article, WithContext } from "schema-dts"
import { t } from "translations"

import Grid from "@mui/material/Grid"
import { styled } from "@mui/material/styles"

import { IArticle } from "data/contentData/interfaces/mongodb/IArticle"
import { IPublicVideo } from "data/contentData/interfaces/mongodb/IPublicVideo"

import Ad from "Components/Ad/Ad"
import ArticleHeader from "Components/Article/ArticleHeader"
import ArticleMeta from "Components/Article/ArticleMeta"
import { ArticleReferences } from "Components/Article/ArticleReferences"
import ArticleVideos from "Components/Article/ArticleVideos"
import LinkedLists from "Components/Article/Widgets/LinkedLists"
import MoreFrom from "Components/Article/Widgets/MoreFrom"
import MostRead from "Components/Article/Widgets/MostRead"
import Related from "Components/Article/Widgets/Related"
import RelatedContentMarketing from "Components/Article/Widgets/RelatedContentMarketing"
import Head from "Components/Head"
import { IPagingContext } from "Components/Layout/PageComponent/PageDataProvider"

import SectionsPaged from "./Paginate/SectionsPaged"
import ToC from "./Paginate/ToC"
import Sections from "./Sections"

export default function DefaultTemplate({
    page,
    paging
}: {
    page: IPublicVideo
    paging: IPagingContext | null
}) {
    const isVideoArticle = page.types.includes("PublicVideo")
    const articleHasTools = page.template?.hasTools ?? false
    const shouldPaginate = paging !== null

    let currenSectionId = paging?.paging.currentPageNumber ?? 0
    if (
        !(page as IArticle).paginateLeadText &&
        page.sections?.[0].type === "root"
    )
        currenSectionId -= 1

    const isFirstSection = currenSectionId === 0
    const showIngressImage = !page.types.includes("PublicVideo") && page.image
    const description =
        page.metadata && page.metadata.description
            ? page.metadata.description
            : undefined

    function getTitle() {
        let title: string
        if (page.metadata && page.metadata.title) title = page.metadata.title
        else title = page.name

        const pagedTitle = getPagedTitle()
        if (pagedTitle) title = `${title} - ${pagedTitle}`

        if (page.url !== "/") title = `${title} - ${t.siteTitle}`

        return title
    }

    function getPagedTitle() {
        if (!paging) return ""

        if (
            paging.paging.currentPageNumber !== null &&
            paging.paging.currentPageNumber > 0
        )
            return paging.pages[paging.paging.currentPageNumber]?.name

        return ""
    }

    function getLdJson() {
        const jsonLd: WithContext<Article> = {
            "@context": "https://schema.org",
            "@type": "Article",
            url: `${process.env.NEXT_PUBLIC_HOST}${page.url}`,
            headline: getTitle(),
            name: getTitle(),
            inLanguage: "no-NO",
            description: description,
            image: showIngressImage ? [page.image.src] : undefined,
            datePublished: page.published
                ? new Date(page.published).toISOString()
                : undefined,
            dateModified: page.latest
                ? new Date(page.latest).toISOString()
                : undefined
        }

        return jsonLd
    }

    function shouldShowHeader() {
        return !paging || (paging && (paging?.showHeader ?? true))
    }

    return (
        <>
            <Head
                page={page}
                paging={paging}
            />
            <AdWrapper>
                <Ad type="toppbanner" />
            </AdWrapper>
            <StyledWrapper
                fullwidth={(isVideoArticle || !articleHasTools).toString()}>
                <StyledArticle>
                    {shouldShowHeader() && (
                        <>
                            <ArticleHeader page={page} />
                            <ArticleMeta page={page} />
                        </>
                    )}

                    {shouldPaginate && isFirstSection && (
                        <ToC
                            page={page}
                            paging={paging}
                        />
                    )}
                    {page.videos && <ArticleVideos videos={page.videos} />}

                    <div className="article">
                        {shouldPaginate ? (
                            <SectionsPaged
                                page={page}
                                paging={paging}
                            />
                        ) : (
                            <Sections sections={page.sections} />
                        )}

                        <LinkedLists linkedLists={page.linkLists} />
                        <ArticleReferences page={page} />
                    </div>
                    {!isVideoArticle && articleHasTools && (
                        <MoreFrom page={page} />
                    )}
                </StyledArticle>
                {!isVideoArticle && articleHasTools && (
                    <ArticleAside>
                        <Ad type="artikkelboard1" />

                        <Related page={page} />
                        <RelatedContentMarketing page={page} />

                        <MostRead />

                        <Ad type="artikkel_skyskraper" />
                    </ArticleAside>
                )}
            </StyledWrapper>
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify(getLdJson())
                }}
            />
        </>
    )
}

const AdWrapper = styled("div")(({ theme }) => ({
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0)
    }
}))

const StyledWrapper = styled("main")<{ fullwidth: string }>(
    ({ theme, fullwidth = "false" }) => ({
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(4),
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
        [theme.breakpoints.up("md")]: {
            display: "grid",
            gridTemplateColumns: fullwidth === "true" ? "1fr" : "1fr 300px",
            padding: theme.spacing(4)
        }
    })
)

const StyledArticle = styled("article")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2)
}))

const ArticleAside = styled(Grid)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2)
}))
