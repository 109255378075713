import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import { IContainerCard } from "data/contentData/interfaces/mongodb/IContainerCard"

import DiseasesCardIcon from "Components/Icon/SvgIcons/DiseasesCardIcon"
import LifestyleCardIcon from "Components/Icon/SvgIcons/LifestyleCardIcon"
import PregnancyCardIcon from "Components/Icon/SvgIcons/PregnancyCardIcon"
import SymptomsCardIcon from "Components/Icon/SvgIcons/SymptomsCardIcon"
import Link from "Components/Link"

export default function BlurbContainer({
    card: containerCard
}: {
    card: IContainerCard
}) {
    return (
        <StyledWrapper>
            {containerCard.cards.map(
                card =>
                    card.more?.href && (
                        <Link
                            key={card.name}
                            href={card.more.href}>
                            <StyledIconWrapper>
                                {IconPicker(card.icon)}
                            </StyledIconWrapper>
                            <Typography
                                variant="h2"
                                align="center">
                                {card.title}
                            </Typography>
                        </Link>
                    )
            )}
        </StyledWrapper>
    )
}

function IconPicker(iconName: string) {
    switch (iconName) {
        case "diseasesCardIcon":
            return <DiseasesCardIcon />
        case "lifestyleCardIcon":
            return <LifestyleCardIcon />
        case "pregnancyCardIcon":
            return <PregnancyCardIcon />
        default:
            return <SymptomsCardIcon />
    }
}

const StyledWrapper = styled("div")(({ theme }) => ({
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    gap: theme.spacing(4),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
        gap: theme.spacing(2),
        margin: theme.spacing(3, 0)
    }
}))

const StyledIconWrapper = styled("div")(({ theme }) => ({
    marginBottom: theme.spacing(2)
}))
