export default function LifestyleCardIcon() {
    return (
        <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 280 170">
            <defs>
                <style>
                    {`
        .clsLifestyle-background-1 {
          stroke: #ffde81;
          stroke-miterlimit: 10;
          stroke-width: 4px;
        }

        .clsLifestyle-background-1, .clsLifestyle-2 {
          fill: none;
        }

        .clsLifestyle-2 {
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 3px;
        }

        .clsLifestyle-3 {
          opacity: .6;
        }

        .clsLifestyle-background-4 {
          fill: #ffde81;
          stroke-width: 0px;
        }`}
                </style>
            </defs>
            <path
                className="clsLifestyle-background-4"
                d="M183.02,141.97c14.75.17,30.75,2.81,43.24-5.04,13.8-8.66,17.9-27.32,15.66-43.46-3.38-24.36-18.85-46.73-40.44-58.5-10.94-5.96-23.13-9.25-35.33-11.77-11.31-2.33-22.9-4.06-34.39-2.94-25.22,2.45-47.02,18.05-70.99,26.27-4.51,1.55-9.2,2.88-13.09,5.64-7.68,5.46-10.71,15.35-12.26,24.64-2.72,16.32-1.99,34.39,8.01,47.57,4.48,5.91,10.51,10.42,16.61,14.63,7.24,4.99,14.84,9.73,23.33,12.02,12.12,3.26,24.95,1.27,37.34-.73,20.43-3.31,41.54-8.57,62.32-8.33"
            />
            <g className="clsLifestyle-3">
                <path
                    className="clsLifestyle-background-1"
                    d="M185.25,146.48c15.91.18,33.18,3.01,46.66-5.39,14.88-9.27,19.31-29.25,16.89-46.52-3.65-26.07-20.34-50.02-43.64-62.62-11.8-6.38-24.95-9.91-38.12-12.6-12.21-2.5-24.7-4.34-37.11-3.15-27.21,2.62-50.74,19.32-76.6,28.11-4.87,1.66-9.93,3.08-14.13,6.04-8.28,5.85-11.55,16.42-13.22,26.37-2.93,17.47-2.14,36.81,8.64,50.92,4.83,6.32,11.34,11.16,17.93,15.66,7.81,5.34,16.01,10.42,25.17,12.86,13.07,3.49,26.92,1.36,40.29-.79,22.04-3.54,44.82-9.17,67.24-8.92"
                />
            </g>
            <g>
                <path
                    className="clsLifestyle-2"
                    d="M136.97,18.3c-4.76-5.48-11.77-8.95-19.61-8.95-14.33,0-25.96,11.62-25.96,25.95.01,9.82,2.76,19.03,7.3,27.44"
                />
                <path
                    className="clsLifestyle-2"
                    d="M182.44,33.1c-1.11-13.31-12.27-23.76-25.87-23.76-7.83,0-14.85,3.47-19.6,8.96"
                />
                <path
                    className="clsLifestyle-2"
                    d="M143.5,160.66s.06-.03.09-.06c12.58-9.51,26.68-17.42,36.64-29.28,4.64-5.54,3.31-10.53,1.58-17.15-1.31-5.03-1.02-10.26-2.37-15.27-1.59-5.88-3.36-9.18-3.13-15.56.04-1.03.34-2.43.84-4.09l17.03-36.98s2.61-5.88-3.6-7.93c-8.47-2.8-13.26,7.04-13.26,7.04l-25.29,41.9c-2.72,4.59-10.12,13.4-15.11,15.36-12.68,4.99-20.02-11.99-24.96-20.96-3.49-6.33-9.64-16.61-18.34-15.46-10.8,1.43-5.85,12.13-3.28,18.32,6.63,15.96.13,33.02,3.05,49.36,2.2,12.27-2.67,21.61-8.28,28.78"
                />
            </g>
        </svg>
    )
}
