export default function SymptomsCardIcon() {
    return (
        <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 280 170">
            <defs>
                <style>
                    {`
        .clsSymptom-1, .clsSymptom-background-2 {
          stroke-miterlimit: 10;
        }

        .clsSymptom-1, .clsSymptom-background-2, .clsSymptom-3 {
          fill: none;
        }

        .clsSymptom-1, .clsSymptom-3 {
          stroke: #010101;
          stroke-width: 3px;
        }

        .clsSymptom-background-4 {
          fill: #add9d0;
          stroke-width: 0px;
        }

        .clsSymptom-5 {
          opacity: .6;
        }

        .clsSymptom-background-2 {
          stroke: #add9d0;
          stroke-width: 4px;
        }

        .clsSymptom-3 {
          stroke-linejoin: round;
        }`}
                </style>
            </defs>
            <path
                className="clsSymptom-background-4"
                d="M181.69,140.04c14.75.17,30.75,2.81,43.24-5.04,13.8-8.66,17.9-27.32,15.66-43.46-3.38-24.36-18.85-46.73-40.44-58.5-10.94-5.96-23.13-9.25-35.33-11.77-11.31-2.33-22.9-4.06-34.39-2.94-25.22,2.45-47.02,18.05-70.99,26.27-4.51,1.55-9.2,2.88-13.09,5.64-7.68,5.46-10.71,15.35-12.26,24.64-2.72,16.32-1.99,34.39,8.01,47.57,4.48,5.91,10.51,10.42,16.61,14.63,7.24,4.99,14.84,9.73,23.33,12.02,12.12,3.26,24.95,1.27,37.34-.73,20.43-3.31,41.54-8.57,62.32-8.33"
            />
            <g className="clsSymptom-5">
                <path
                    className="clsSymptom-background-2"
                    d="M183.91,144.54c15.91.18,33.18,3.01,46.66-5.39,14.88-9.27,19.31-29.25,16.89-46.52-3.65-26.07-20.34-50.02-43.64-62.62-11.8-6.38-24.95-9.91-38.12-12.6-12.21-2.5-24.7-4.34-37.11-3.15-27.21,2.62-50.74,19.32-76.6,28.11-4.87,1.66-9.93,3.08-14.13,6.04-8.28,5.85-11.55,16.42-13.22,26.37-2.93,17.47-2.14,36.81,8.64,50.92,4.83,6.32,11.34,11.16,17.93,15.66,7.81,5.34,16.01,10.42,25.17,12.86,13.07,3.49,26.92,1.36,40.29-.79,22.04-3.54,44.82-9.17,67.24-8.92"
                />
            </g>
            <g>
                <path
                    className="clsSymptom-1"
                    d="M150.25,23.82c2.8,0,7.2.95,7.2,14.32,0,16.7-15.21,65.85-43.23,65.85s-43.23-49.15-43.23-65.85c0-13.36,4.4-14.32,7.2-14.32"
                />
                <path
                    className="clsSymptom-3"
                    d="M176.67,115.44v17.18c0,12.65-8.6,22.9-19.21,22.9h-24.01c-10.61,0-19.21-10.26-19.21-22.9v-28.63"
                />
                <circle
                    className="clsSymptom-1"
                    cx="176.67"
                    cy="103.98"
                    r="11.44"
                />
                <circle
                    className="clsSymptom-1"
                    cx="85.74"
                    cy="24.78"
                    r="7.35"
                />
                <circle
                    className="clsSymptom-1"
                    cx="141.78"
                    cy="24.78"
                    r="7.35"
                />
            </g>
        </svg>
    )
}
